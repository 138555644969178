<template>
  <div>
    <a-modal :bodyStyle="{height:'65vh',overflowY:'scroll'}" :visible="visible" title="分红配置" @ok="submitForm" @cancel="modalHide">
      <!-- <div class="tips">按各角色名下绑定设备总营业额百分比计算；0代表不参与分红</div> -->
      <a-form-model v-show="type != 3 || formData.proxy_power != 1" ref="shareForm" :model="formData" :rules="rules" layout="vertical">
        <a-form-model-item label="分红模板">
          <a-row>
            <a-col :span="16">
              <a-select v-model="formData.show_id" @change="templateChange" placeholder="请选择" >
                <a-select-option v-for="(item, index) in templateList" :value="item.show_id" :data-index="index"
                  :key="item.show_id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="平台分红比例 %" required style="margin-bottom: 0;">
          <a-row>
            <a-col :span="10">
              <a-input value="康皇科技" disabled />
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="4">
              <a-form-model-item prop="platform_division">
                <a-input-number
                  style="width: 100%"
                  v-model="formData.platform_division"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                  :disabled="!!formData.show_id"
                  :min="0"
                  :max="100"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="医院对账类型">
          <a-radio-group v-model="formData.type" disabled>
            <a-radio value="1">
              个人
            </a-radio>
            <a-radio value="2">
              对公
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="医院分红比例 %"  style="margin-bottom: 0;" required>
          <a-row>
            <a-col :span="10">
              <a-input :value="hospital_account.hospital_name" disabled />
            </a-col>
            <a-col :span="2" class="my-center">
              ——
            </a-col>
            <a-col :span="4">
              <a-form-model-item prop="hospital_division">
                <a-input-number
                  style="width: 100%"
                  v-model="formData.hospital_division"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                  :disabled="!!formData.show_id"
                  :min="0"
                  :max="100"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="7" class="my-center">
              <a-checkbox @change="onCheckChange" :checked="proxy_power">下放代理商权限</a-checkbox>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item style="margin-bottom: 0;" label="一级代理分红比例 %" required>
          <a-row>
            <a-col :span="10">
              <a-form-model-item  prop="proxy_account_id">
                  <a-select v-model="formData.proxy_account_id" placeholder="请选择" @change="changeProxy">
                    <a-select-option v-for="(item, index) in firstAgentList" :value="item.account_id" :data-index="index"
                      :key="item.account_id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="4">
              <a-form-model-item  prop="proxy_division">
                <a-input-number
                  style="width: 100%"
                  v-model="formData.proxy_division"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                  :disabled="!!formData.show_id"
                  :min="0"
                  :max="100"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>
      </a-form-model>
      <div v-if="agentFlag == 1">
        <div style="color: red;margin-bottom: 20px;" v-if="type == 3 && formData.proxy_power == 1">提示：可分配比例为{{formData.proxy_division}}%</div>
        <div v-else style="font-weight: 700;font-size: 20px;margin-bottom: 20px;">代理商配置</div>
        <a-form-model ref="newAgentRef"  :model="agentData" :rules="agentRules" layout="vertical">
          <a-form-model-item required style="margin-bottom: 0;" label="选择用户分配比例 %">
            <a-row>
              <a-col :span="6">
                <a-input :value="fixList[0].name" disabled />
              </a-col>
              <a-col :span="2" class="my-center">
                <div>——</div>
              </a-col>
              <a-col :span="8">
                <a-select disabled v-model="formData.proxy_account_id" placeholder="请选择">
                  <a-select-option v-for="(item, index) in firstAgentList" :value="item.account_id" :data-index="index"
                    :key="item.account_id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="2" class="my-center">
                <div>——</div>
              </a-col>
              <a-col :span="4">
                <a-form-model-item prop="proxy_division">
                  <a-input-number
                    style="width: 100%"
                    v-model="agentData.proxy_division"
                    :formatter="value => `${value}%`"
                    :parser="value => value.replace('%', '')"

                    :min="0"
                    :max="100"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model-item>    
          <a-form-model-item label="护士分红" required>
            <a-radio-group v-model="agentData.nurse_power" @change="changeSelectHs">
              <a-radio :value="1">
                不参与分红
              </a-radio>
              <a-radio :value="2">
                指定护士
              </a-radio>
              <a-radio :value="3">
                扫码绑定分红
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item required style="margin-bottom: 0;" label="用户分配比例 %" v-if="agentData.nurse_power != 1">
            <a-row>
              <a-col :span="6">
                <a-input :value="fixList[1].name" disabled />
              </a-col>
              <a-col :span="2" class="my-center">
                <div>——</div>
              </a-col>
              <a-col :span="8">
                <a-form-model-item prop="hs_id" v-if="agentData.nurse_power == 2">
                  <a-select  v-model="agentData.hs_id" placeholder="请选择">
                    <a-select-option v-for="(item, index) in secondAgentList" :value="item.account_id" :data-index="index"
                      :key="item.account_id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item prop="hs_id" v-if="agentData.nurse_power == 3">
                  <a-select mode="multiple"  v-model="agentData.hs_id" placeholder="请选择">
                    <a-select-option v-for="(item, index) in secondAgentList" :value="item.account_id" :data-index="index"
                      :key="item.account_id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="2" class="my-center">
                <div>——</div>
              </a-col>
              <a-col :span="4">
                <a-form-model-item prop="hs_division">
                  <a-input-number
                    style="width: 100%"
                    v-model="agentData.hs_division"
                    :formatter="value => `${value}%`"
                    :parser="value => value.replace('%', '')"
                    :min="0"
                    :max="100"
                  />
                </a-form-model-item>
              </a-col>
            
            </a-row>
          </a-form-model-item>  
          <a-form-model-item required style="margin-bottom: 0;" v-for="(list,listIndex) in agentData.userList" :key="listIndex" label="选择用户分配比例 %">
            <a-row>
              <a-col :span="6">
                <a-form-model-item  :prop="'userList.' + listIndex + '.role_id'" :rules="{required: true,message: '请选择角色',trigger: 'blur',}">
                    <a-select v-model="list.role_id" placeholder="请选择">
                      <a-select-option v-for="(item, index) in roleList" :value="item.role_id" :data-index="index"
                        :key="item.role_id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="2" class="my-center">
                <div>——</div>
              </a-col>
              <a-col :span="8">
                <a-form-model-item  :prop="'userList.' + listIndex + '.account_id'" :rules="{required: true,message: '请选择用户',trigger: 'blur',}">
                    <a-select v-model="list.account_id" placeholder="请选择">
                      <a-select-option v-for="(item, index) in secondAgentList" :value="item.account_id" :data-index="index"
                        :key="item.account_id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="2" class="my-center">
                <div>——</div>
              </a-col>
              <a-col :span="4">
                <a-form-model-item  :prop="'userList.' + listIndex + '.division'" :rules="{required: true,message: '请输入比例',trigger: 'blur',}">
                  <a-input-number
                    style="width: 100%"
                    v-model="list.division"
                    :formatter="value => `${value}%`"
                    :parser="value => value.replace('%', '')"
                    :min="0"
                    :max="100"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="2" class="my-center">
                <div style="color:red;cursor: pointer;" @click="delAgent(listIndex)">移除</div>
              </a-col>
            </a-row>
          </a-form-model-item>   
        </a-form-model>
        <div>
          <a-button class="my-button"  type="dashed" @click="addUser"  primary>+添加分红用户</a-button>
        </div>
      </div>
      <div v-if="agentFlag == 0">
        <a-button class="my-button"  type="dashed" @click="addAgent"  primary>+添加代理商分红配置</a-button>
      </div>
    </a-modal>
    <a-modal :visible="agentVisible" title="分红配置" @ok="submitAgentForm" @cancel="modalAgentHide">
      <div style="color: red;margin-bottom: 20px;">提示：可分配比例为{{formData.proxy_division}}%</div>
      <a-form-model ref="agentForm"  :model="agentData" :rules="agentRules" layout="vertical">
        <a-form-model-item required style="margin-bottom: 0;" label="选择用户分配比例 %">
          <a-row>
            <a-col :span="6">
              <a-input :value="fixList[0].name" disabled />
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="8">
              <a-select disabled v-model="formData.proxy_account_id" placeholder="请选择">
                <a-select-option v-for="(item, index) in firstAgentList" :value="item.account_id" :data-index="index"
                  :key="item.account_id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="4">
              <a-form-model-item prop="proxy_division">
                <a-input-number
                  style="width: 100%"
                  v-model="agentData.proxy_division"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                  :min="0"
                  :max="100"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>    
        <a-form-model-item label="护士分红" required>
          <a-radio-group v-model="agentData.nurse_power" @change="changeSelectHs">
            <a-radio :value="1">
              不参与分红
            </a-radio>
            <a-radio :value="2">
              指定护士
            </a-radio>
            <a-radio :value="3">
              扫码绑定分红
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item required style="margin-bottom: 0;" label="用户分配比例 %" v-if="agentData.nurse_power != 1">
          <a-row>
            <a-col :span="6">
              <a-input :value="fixList[1].name" disabled />
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="hs_id" v-if="agentData.nurse_power == 2">
                <a-select  v-model="agentData.hs_id" placeholder="请选择">
                  <a-select-option v-for="(item, index) in secondAgentList" :value="item.account_id" :data-index="index"
                    :key="item.account_id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item prop="hs_id" v-if="agentData.nurse_power == 3">
                <a-select mode="multiple"  v-model="agentData.hs_id" placeholder="请选择">
                  <a-select-option v-for="(item, index) in secondAgentList" :value="item.account_id" :data-index="index"
                    :key="item.account_id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="4">
              <a-form-model-item prop="hs_division">
                <a-input-number
                  style="width: 100%"
                  v-model="agentData.hs_division"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                  :min="0"
                  :max="100"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>  
        <a-form-model-item required style="margin-bottom: 0;" v-for="(list,listIndex) in agentData.userList" :key="listIndex" label="选择用户分配比例 %">
          <a-row>
            <a-col :span="6">
              <a-form-model-item  :prop="'userList.' + listIndex + '.role_id'" :rules="{required: true,message: '请选择角色',trigger: 'blur',}">
                  <a-select v-model="list.role_id" placeholder="请选择">
                    <a-select-option v-for="(item, index) in roleList" :value="item.role_id" :data-index="index"
                      :key="item.role_id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="8">
              <a-form-model-item  :prop="'userList.' + listIndex + '.account_id'" :rules="{required: true,message: '请选择用户',trigger: 'blur',}">
                  <a-select v-model="list.account_id" placeholder="请选择">
                    <a-select-option v-for="(item, index) in secondAgentList" :value="item.account_id" :data-index="index"
                      :key="item.account_id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2" class="my-center">
              <div>——</div>
            </a-col>
            <a-col :span="4">
              <a-form-model-item  :prop="'userList.' + listIndex + '.division'" :rules="{required: true,message: '请输入比例',trigger: 'blur',}">
                <a-input-number
                  style="width: 100%"
                  v-model="list.division"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                  :min="0"
                  :max="100"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2" class="my-center">
              <div style="color:red;cursor: pointer;" @click="delAgent(listIndex)">移除</div>
            </a-col>
          </a-row>
        </a-form-model-item>   
      </a-form-model>
      <div>
        <a-button class="my-button"  type="dashed" @click="addUser"  primary>+添加分红用户</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
// 把接口放在一个文件里进行维护了,
import { domain } from '@/siteInfo.js'
import { shareMoneyConfig, shareMoneyConfigInfo, templateSelect } from '@/api/equipment.js'
import { reconciliationAccountSelect } from '@/api/reconciliation.js'
import Cookies from 'js-cookie'
export default {
  props: {
    equipment_id: {
      type: Array, // 指定类型为Array
      default: () => [], // 默认值是一个空数组
      //type: [String, Number],
      //default: ''
    }
  },
  data() {
    return {
      visible: false,
      agentVisible:false,
      formData: {
        nurse_power:1,
      },
      cookUserInfo:{},
      moneyPlanList: [],
      proxy_power:false,
      agentData:{
        nurse_power:1,
        proxy_division:"",
        userList:[],
      },
      agentRules:{
        proxy_division:[{ required: true, message: '请输入比例', trigger: 'change' }],
        hs_id:[{ required: true, message: '请选择用户', trigger: 'change' }],
        agent_division:[{ required: true, message: '请选择', trigger: 'blur' }],
        hs_division:[{ required: true, message: '请输入比例', trigger: 'change' }],
      },
      rules: {
        proxy_account_id:[{ required: true, message: '请选择一级代理商', trigger: 'change' }],
        hospital_division:[{ required: true, message: '请输入比例', trigger: 'change' }],
        platform_division:[{ required: true, message: '请输入比例', trigger: 'change' }],
        proxy_division:[{ required: true, message: '请输入比例', trigger: 'change' }],
      },
      roleList:[],
      templateList: [],
      hospital_account:{},
      firstAgentList:[],
      secondAgentList:[],
      fixList:[],
      dividend_template:{},
      agentFlag:0,
      type:Cookies.getJSON('userInfo').type
    }
  },
  watch: {
    visible(value) {
   
      if (value && this.equipment_id && this.equipment_id.length == 1) {
        this.formData.equipment_id = this.equipment_id[0]
        this.getInfo(this.equipment_id[0])
        this.agentFlag = 0
        this.$refs.shareForm.resetFields();
      } else {
        console.log(value)
      }
    }
  },
  async created() {
    this.cookUserInfo = Cookies.getJSON('userInfo')
    // 这里
    const template = await templateSelect({})
    this.templateList = template.data
    //一级代理商用户
    reconciliationAccountSelect({level:1}).then(({data})=>{
      this.firstAgentList = data.list
    })
    //获取角色
    this.$post(domain + '/admin/v1/reconciliation/accountRole', {}).then(res => {
      if(res.code === 0){
        this.roleList = res.data.filter(item=>item.fixed == 2)
        this.fixList = res.data.filter(item=>item.fixed == 1)
      }
			})
  },
  mounted() {},
  methods: {
    templateChange(value) {
      const obj = this.templateList.find(item => item.show_id === value)
      this.formData = {...this.formData,platform_division:Number(obj.platform_division),
        hospital_division:Number(obj.hospital_division),proxy_division:Number(obj.proxy_division)}
    },
    getSecondAccount(){
      //二级代理商用户
      reconciliationAccountSelect({level:2,super_id:this.formData.proxy_account_id}).then(({data})=>{
        this.secondAgentList = data.list
      })
    },
    changeProxy(){
      this.getSecondAccount();
      this.agentData = {
        // proxy_division:this.agentData?.proxy_division,
        nurse_power:this.agentData.nurse_power,
        proxy_division:"",
        userList:[],
      }
    },
    modalHide() {
      this.visible = false
      if(this.equipment_id.length == 1){
        this.getInfo(this.equipment_id[0])
      }
    },
    modalAgentHide(){
      this.agentVisible = false
    },  
    onCheckChange(e){
      this.proxy_power = e.target.checked
    },
    async getInfo(id) {
      const res = await shareMoneyConfigInfo({ equipment_id: id })
      if (res.code === 0) {
        // this.formData = res.data
        this.hospital_account = res.data.hospital_account
        this.dividend_template = res.data.dividend_template
        this.formData = { ...this.dividend_template }
        if(this.dividend_template?.agent_list.length > 1){
          let agent_list = this.dividend_template.agent_list
          this.agentFlag = 1
          this.agentData={...this.agentData,proxy_division:agent_list[0].division,nurse_power:this.formData.nurse_power,
            hs_id:agent_list[1].account_id,hs_division:agent_list[1].division}
          if(this.dividend_template.nurse_power == 3){
            this.agentData.hs_id = agent_list[1].account_id.split(',')
          }
          if(this.dividend_template?.agent_list.length > 2&&this.dividend_template?.nurse_power != '1'){
            this.agentData.userList = this.dividend_template.agent_list.slice(2)
          }else if(this.dividend_template?.agent_list.length > 1&&this.dividend_template?.nurse_power == '1'){
            this.agentData.userList = this.dividend_template.agent_list.slice(1)
          }else{
            this.agentData.userList = []
          }
          
          this.getSecondAccount()
        }
      
      //  this.equipment_id = res.data.equipment_id
        if(this.formData.proxy_power == 1){
          this.proxy_power = true
        }else{
          this.proxy_power = false
        }
        this.formData.type = res.data?.hospital_account?.account?.type
      }else if(res.code == -2){
        this.$message.warn(res.msg, 1.5)
        this.visible = false
      }
    },
    confirmSubmit() { },
    //选择护士分红
    changeSelectHs(){
      delete this.agentData.hs_id
      delete this.agentData.hs_division
    },
    //配置代理商
    addAgent(){
      if(!this.formData.proxy_account_id){
        this.$message.warning('请选择一级代理商')
        return
      }
      if(!this.formData.proxy_division){
        this.$message.warning('请输入一级代理分红比例')
        return
      }
      this.agentVisible = true
      this.agentData = {
        nurse_power:1,
        userList:[],
      }
      this.getSecondAccount()
      setTimeout(()=>{
        //this.$refs.agentForm.resetFields();
      },100)
      
    },
    //添加用户
    addUser(){
      this.agentData.userList.push({
        role_id:"",
        account_id:"",
        division:""
      })

    },
    //移除用户
    delAgent(index){
      this.agentData.userList.splice(index,1)
    },
    submitAgentForm(){
      this.$refs.agentForm.validate(valid=>{
        if(valid){
          this.agentFlag = 1
          this.agentVisible = false
        }
      })
    },
    async getForm(){
      let total =
        Number(this.formData.platform_division) +
        Number(this.formData.hospital_division) +
        Number(this.formData.proxy_division)
      if (total !== 100) {
        this.$message.warning('请合理分配分红方式')
        return false
      }
      let {show_id,platform_division,hospital_division,proxy_division,proxy_account_id} = this.formData
      let form = {
        equipment_ids:this.equipment_id,
        show_id,
        proxy_division,
        platform_division,
        hospital_division,
        proxy_power:this.proxy_power ? 1 : 2,
        nurse_power:this.agentData.nurse_power,
      }
      form.agent_list = []
        form.agent_list.push({
          role_id:this.fixList[0].role_id, 
          account_id:this.formData.proxy_account_id, 
          division:this.agentFlag == 1? this.agentData.proxy_division : this.formData.proxy_division
        })

      if(this.agentFlag == 1 && this.agentData.nurse_power != 1){
        let account_id = ""
        if(this.agentData.nurse_power == 3){
          account_id = this.agentData.hs_id.join(",")
        }
        form.agent_list.push({
          role_id:this.fixList[1].role_id, 
          account_id:this.agentData.nurse_power == 3 ? account_id : this.agentData.hs_id, 
          division:this.agentData.hs_division
        })

      }
      this.agentData.userList.forEach(item=>{
          form.agent_list.push({
          role_id:item.role_id, 
          account_id:item.account_id, 
          division:item.division
        })
      })
      const res = await shareMoneyConfig(form)
      if (res.code === 0) {
        this.visible = false
        this.$message.success('分红设置', 1.5)
      } else {
        this.$message.success(res.msg || '分红设置失败', 1.5)
      }
      this.$emit('updateData')
    },
    submitForm() {
      this.$refs.shareForm.validate(async valid => {
        if (valid) {
          if(this.agentFlag == 1){
            this.$refs.newAgentRef.validate(async valid1 =>{
              if(valid1){
                  this.getForm()
                }
              })
          }else{
            this.getForm()
          }
          } else {
            console.log('error submit!!')
            return false
          }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.my-center{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
}
.my-button{
  color: #40a9ff;
  width: 68%;
  display: block;
}
</style>
