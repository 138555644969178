<template>
  <a-modal :visible="visible" title="修改设备" @ok="submitForm" @cancel="modalHide">
    <a-form-model ref="bindForm" :model="formData" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-form-model-item label="计费方案" prop="plan_id">
        <a-select v-model="formData.plan_id" @change="moneyPlanChange" placeholder="请选择">
          <a-select-option v-for="(item, index) in moneyPlanList" :value="item.plan_id" :data-index="index"
            :key="item.plan_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="门诊预付" prop="plan_id">
        <a-radio-group v-model="formData.outpatient_pay">
          <a-radio :value="2">
            普通缴费
          </a-radio>
          <a-radio :value="1">
            门诊预付
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="医院" prop="hospital_id">
        <a-select v-model="formData.hospital_id" @change="hospitalChange" placeholder="请选择">
          <a-select-option v-for="(item, index) in hospitalList" :value="item.hospital_id" :data-index="index"
            :key="item.hospital_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="科室" prop="department_id">
        <a-select v-model="formData.department_id" placeholder="请选择">
          <a-select-option v-for="(item, index) in departmentList" :value="item.department_id" :data-index="index"
            :key="item.department_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="医院收账" prop="account_hospital_id">
        <a-select v-model="formData.account_hospital_id" placeholder="请选择">
          <a-select-option v-for="(item, index) in accountHospitalList" :value="item.account_id" :label="item.label"
            :data-index="index" :key="item.account_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="代理商" prop="account_agent_id">
        <a-select v-model="formData.account_agent_id" placeholder="请选择">
          <a-select-option v-for="(item, index) in accountAgentList" :value="item.account_id" :label="item.label"
            :data-index="index" :key="item.account_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="护士" prop="account_nurse_id">
        <a-select v-model="formData.account_nurse_id" placeholder="请选择">
          <a-select-option v-for="(item, index) in accountNurseList" :value="item.account_id" :label="item.label"
            :data-index="index" :key="item.account_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="运营" prop="account_operator_id">
        <a-select v-model="formData.account_operator_id" placeholder="请选择">
          <a-select-option v-for="(item, index) in accountOperatorList" :value="item.account_id" :label="item.label"
            :data-index="index" :key="item.account_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>

<script>
// 把接口放在一个文件里进行维护了,
import { hospitalLSelect, departmentList } from '@/api/hospital'
import { planSelect } from '@/api/moneyPlan'
import { reconciliationAccountSelect } from '@/api/reconciliation'
import { equipmentBindEdit } from '@/api/equipment.js'
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      dataForm: this.$form.createForm(this, { name: 'dataForm' }),
      hospitalList: [{ hospital_id: '', name: '请选择' }], //医院列表
      departmentList: [{ department_id: '', name: '请选择' }], // 科室列表
      // 我看对账用户列表这个接口像是获取代理商,医院,护士
      accountHospitalList: [{ account_id: '', name: '无' }], // 医院收账列表
      accountAgentList: [{ account_id: '', name: '无' }], // 代理商列表
      accountNurseList: [{ account_id: '', name: '无' }], // 护士列表
      accountOperatorList: [{ account_id: '', name: '无' }], // 运营列表
      // 这个少个医院id 的参数吧,应当有个医院的id参数,hospital_id
      formData: {
        plan_id: '', //计费方式id
        hospital_id: '', //医院
        department_id: '', // 科室
        account_hospital_id: '', //医院收账
        account_agent_id: '', //代理商
        account_nurse_id: '', //护士
        account_operator_id: '', //护士
        outpatient_pay: ''
      },
      moneyPlanList: [],
      rules: {
        plan_id: [{ required: true, message: '请选择计费方案', trigger: 'change' }],
        hospital_id: [{ required: true, message: '请选择医院', trigger: 'change' }],
        department_id: [{ required: true, message: '请选择科室', trigger: 'change' }],
        // account_hospital_id: [{required: true, message: '请选择医院收账', trigger: 'change'}],
        // account_agent_id: [{required: true, message: '请选择代理商', trigger: 'change'}],
        // account_nurse_id: [{required: true, message: '请选择护士', trigger: 'change'}]
      },
      defaultItem: { account_id: '', name: '无' }
    }
  },
  watch: {
    info: {
      handler(val) {
        this.formData = val
        console.log(this.formData)
        this.hospitalChange()
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    // 这里
    const res = await hospitalLSelect()
    console.log('医院请求结果', res)
    if (res.code === 0) {
      this.hospitalList = [{ hospital_id: '', name: '请选择' }, ...res.data]
    }
    this.getMoneyPlan()
    this.getAccountList()
  },
  mounted() { },
  methods: {
    async getAccountList() {
      const hospitalRes = await reconciliationAccountSelect({ type: 2 })
      const agentRes = await reconciliationAccountSelect({ type: 1 })
      const nurseRes = await reconciliationAccountSelect({ type: 3 })
      const OpRes = await reconciliationAccountSelect({ type: 4 })
      if (hospitalRes.code === 0) {
        this.accountHospitalList = [this.defaultItem, ...hospitalRes.data.list]
      }
      if (agentRes.code === 0) {
        this.accountAgentList = [this.defaultItem, ...agentRes.data.list]
      }
      if (nurseRes.code === 0) {
        this.accountNurseList = [this.defaultItem, ...nurseRes.data.list]
      }
      if (OpRes.code === 0) {
        this.accountOperatorList = [this.defaultItem, ...OpRes.data.list]
      }
    },
    async getMoneyPlan() {
      const res = await planSelect()
      let { code, data, msg } = res
      if (code === 0) {
        this.moneyPlanList = [{ plan_id: '', name: '请选择' }, ...data]
      }
    },
    hospitalChange(e) {
      console.log('医院改变')
      console.log(this.formData.hospital_id)
      this.getDepartMents()
    },
    async getDepartMents() {
      console.log('医院的id', this.formData.hospital_id)
      const res = await departmentList({ hospital_id: this.formData.hospital_id, limit: 9999 })
      if (res.code === 0) {
        this.departmentList = [{ department_id: '', name: '请选择' }, ...res.data.list]
      }
    },
    submitForm() {
      this.$refs.bindForm.validate(async valid => {
        if (valid) {
          const res = await equipmentBindEdit({ ...this.formData })
          if (res.code === 0) {
            this.visible = false
            this.$message.success('修改成功', 1.5)
          } else {
            this.$message.error(res.msg || '修改失败', 1.5)
          }
          this.$emit('updateData')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    moneyPlanChange(e) { },
    bindMachine() { },
    modalHide() {
      this.$refs.bindForm.resetFields()
      this.formData.equipment_id = []
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped></style>
